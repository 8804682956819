<div class="background">
  <div class="login p-5">
    <svg-icon
      [src]="environment.assetsUrl + 'logo-black.svg'"
      [svgClass]="'pb-5 text-center'"
      [svgAriaLabel]="'Adtrac logo black'"
    ></svg-icon>

    <h3 class="mat-h3 pb-2">{{ 'login' | translate }}</h3>
    <div class="grey-text mb-3"><small translate>loginDetail</small></div>
    <form [formGroup]="form" (ngSubmit)="login()">
      <mat-form-field>
        <mat-label>{{ 'user.email' | translate }}</mat-label>

        <input
          autocomplete="username"
          matInput
          tabindex="1"
          type="email"
          formControlName="email"
          [showMatError]="emailMatError"
          clearInput
        />
        <mat-error #emailMatError />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'user.password' | translate }}</mat-label>
        <input
          autocomplete="current-password"
          matInput
          clearInput
          type="password"
          tabindex="2"
          formControlName="password"
          [showMatError]="passwordMatError"
        />
        <mat-error #passwordMatError />
      </mat-form-field>
      <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
        @if (isExpanded) {
          <mat-form-field>
            <mat-label>{{ 'loginScreen.securityTokenTitle' | translate }}</mat-label>
            <input
              autofocus
              autocomplete="token"
              matInput
              type="text"
              formControlName="token"
              [showMatError]="tokenError"
              tabindex="0"
            />
            <mat-hint>{{ 'loginScreen.securityTokenHint' | translate }}</mat-hint>
            <mat-error #tokenError />
          </mat-form-field>
        }
      </div>
      <button
        class="mt-4"
        type="submit"
        id="login_submit_btn"
        appInvalidControlScroll
        [buttonLoader]="loading"
        mat-flat-button
        color="primary"
      >
        {{ 'button.submit' | translate }}
      </button>
    </form>
  </div>
</div>
