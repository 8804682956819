import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '@app/core/services/snackbar/snack-bar.service';
import { AuthenticationService } from '@auth/services/authentication.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { QRCodeComponent } from 'angularx-qrcode';
import { EMPTY, catchError, debounceTime, filter, finalize, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ButtonLoaderDirective } from '../../../../shared/directives/button-loader/button-loader.directive';
import { ClearInputDirective } from '../../../../shared/directives/clear-input/clear-input.directive';
import { InvalidControlScrollDirective } from '../../../../shared/directives/invalid-control-scroll/invalid-control-scroll';
import { ShowMatErrorDirective } from '../../../../shared/directives/show-mat-error/show-mat-error.directive';
import { AuthState } from '../../store/auth.state';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-2fa-setup',
  templateUrl: './2fa-setup.component.html',
  styleUrls: ['./2fa-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClearInputDirective,
    ShowMatErrorDirective,
    MatButtonModule,
    InvalidControlScrollDirective,
    ButtonLoaderDirective,
    MatIconModule,
    TranslateModule,
    QRCodeComponent,
    AngularSvgIconModule,
  ],
})
export class TwoFASetupComponent implements OnInit {
  public environment = environment;
  public form: FormGroup;
  public loading = false;
  public qrCode: string;
  public secret: string;

  private router = inject(Router);
  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  private snackBar = inject(SnackBarService);
  private authenticationService = inject(AuthenticationService);
  private _cdr = inject(ChangeDetectorRef);
  private activatedRoute = inject(ActivatedRoute);
  private translateService = inject(TranslateService);

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) => {
      const { qrCode, secret } = response.data;
      this.qrCode = qrCode;
      this.secret = secret;
      this._cdr.markForCheck();
    });

    this.form = new FormGroup({
      token: new FormControl('', Validators.required),
    });

    this.form
      .get('token')
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        filter((token) => token.length == 6),
      )
      .subscribe(() => this.submit());
  }

  submit() {
    this.form.markAllAsTouched();
    if (!!this.form.valid) {
      this.loading = true;
      this._cdr.markForCheck();

      this.store
        .select(AuthState.twoFaToken)
        .pipe(
          filter((_) => !!_),
          take(1),
          switchMap((token) =>
            this.authenticationService
              .complete2FASetup({ mfaAccessToken: token, otp_token: this.form.value.token })
              .pipe(take(1)),
          ),
          catchError((error) => {
            (error.status === 400 || error.status === 401) &&
              this.snackBar.warn(this.translateService.instant('loginScreen.errors.wrongToken'));
            return EMPTY;
          }),
          takeUntilDestroyed(this.destroyRef),
          finalize(() => {
            this.loading = false;
            this._cdr.markForCheck();
          }),
        )
        .subscribe((_) => {
          this.loading = false;
          this._cdr.markForCheck();
          this.router.navigate(['/']);
        });
    }
  }
}
