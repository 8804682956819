<div class="background">
  <div class="login p-5">
    <svg-icon
      [src]="environment.assetsUrl + 'logo-black.svg'"
      [svgClass]="'pb-5'"
      [svgAriaLabel]="'Adtrac logo black'"
    ></svg-icon>
    <h3 class="mat-h3 pb-2">{{ '2faSetup' | translate }}</h3>

    <h4 class="mat-h5 grey-text text-center">{{ '2faSetupHint' | translate }}</h4>
    <qrcode cssClass="qr-wrapper" [qrdata]="qrCode" [width]="256"></qrcode>
    <h4 class="mat-h5 grey-text text-center">{{ '2faSetupHint2' | translate }}</h4>
    <h4 class="mat-h3 text-center mt-2">{{ secret }}</h4>

    <form [formGroup]="form" (ngSubmit)="submit()" class="mt-3">
      <mat-form-field>
        <mat-label>{{ 'loginScreen.securityTokenTitle' | translate }}</mat-label>
        <input
          autofocus
          matInput
          clearInput
          type="text"
          formControlName="token"
          required
          id="token"
          [showMatError]="tokenError"
        />
        <mat-hint>{{ 'loginScreen.securityTokenHint' | translate }}</mat-hint>
        <mat-error #tokenError />
      </mat-form-field>

      <button
        [buttonLoader]="loading"
        type="submit"
        mat-flat-button
        color="primary"
        class="mt-4"
        appInvalidControlScroll
        id="change_password_btn_submit"
      >
        {{ 'button.submit' | translate }}
      </button>
    </form>

    <!-- <a class="navigate-back mt-3" [routerLink]="['/']">
      <mat-icon>chevron_left</mat-icon> {{ 'button.back' | translate }}
    </a> -->
  </div>
</div>
