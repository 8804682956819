import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { SetPasswordForm } from '@auth/models/auth.model';
import { AuthenticationService } from '@auth/services/authentication.service';
import { SnackBarService } from '@core/services/snackbar/snack-bar.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { validatePasswordConfirmation } from '@shared/validators/validators';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ButtonLoaderDirective } from '@shared/directives/button-loader/button-loader.directive';
import { ClearInputDirective } from '@shared/directives/clear-input/clear-input.directive';
import { InvalidControlScrollDirective } from '@shared/directives/invalid-control-scroll/invalid-control-scroll';
import { ShowMatErrorDirective } from '@shared/directives/show-mat-error/show-mat-error.directive';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClearInputDirective,
    ShowMatErrorDirective,
    MatButtonModule,
    InvalidControlScrollDirective,
    ButtonLoaderDirective,
    TranslateModule,
    AngularSvgIconModule,
  ],
})
export class SetPasswordComponent implements OnInit {
  form: SetPasswordForm;
  token: string;
  public legalEntityName: string;
  loading = false;
  environment = environment;
  private formBuilder = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  _snackBarService = inject(SnackBarService);
  _translateService = inject(TranslateService);
  private _cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    this.legalEntityName = this.route.snapshot.queryParams['legalEntityName'];

    const passwordValidators = [Validators.required, Validators.minLength(environment?.['passwordMinLength'] ?? 8)];

    this.form = this.formBuilder.group(
      {
        password: ['', passwordValidators],
        confirmPassword: ['', passwordValidators],
      },
      { validators: validatePasswordConfirmation }
    );
    this._cdr.markForCheck();
  }

  submit() {
    this.form.markAllAsTouched();
    this._cdr.markForCheck();
    if (!!this.form.valid) {
      this.loading = true;
      this._cdr.markForCheck();
      this.authenticationService
        .setPassword(
          { password: this.form.value.password, confirmPassword: this.form.value.confirmPassword },
          this.token
        )
        .pipe(
          catchError((err) => {
            const errorString =
              err?.error
                ?.flatMap((item) => JSON.parse(item?.replace(/'/g, '"')))
                ?.join(' ')
                ?.toString() || this._translateService.instant('general.errors.unknownServerError');
            this._snackBarService.warn(errorString);
            // this._translateService.instant('linkExpiredError'));
            return null;
          })
        )
        .subscribe((_) => {
          this.loading = false;
          this._cdr.markForCheck();
          this.router.navigate(['/']);
        });
    }
  }
}
